var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mx-auto",attrs:{"tile":"","flat":"","disabled":_vm.disabled,"loading":_vm.disabled}},[_c('v-card-text',[_vm._t("top"),(_vm.files.length > 0)?_c('v-list',{attrs:{"two-line":"","dense":""}},[_vm._l((_vm.files),function(file){return [_c('v-divider',{key:("div_" + (file.id))}),_c('v-list-item',{key:("item_" + (file.id))},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"large":"","color":"grey lighten-2"},domProps:{"textContent":_vm._s(
                  file.driver == 'google'
                    ? 'mdi-google-drive'
                    : 'mdi-database'
                )}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(((file.name) + " (" + (file.extension) + ")"))}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(!!file.comment ? file.comment : '')}})],1),_c('v-list-item-action',[_c('v-row',[_c('v-btn',{attrs:{"disabled":!file.preview || file.driver != 'google',"icon":"","href":file.preview,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-book-open-variant")])],1),_c('v-btn',{attrs:{"icon":"","href":file.url,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-download")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.editImage(file)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('abp-confirm',{attrs:{"title":"Действительно удалить?","text":"Внимание! Файл удаляется без возможности восстановления"},on:{"confirmPress":function($event){return _vm.removeFile(file.id)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true),model:{value:(_vm.deleteFileConfirm),callback:function ($$v) {_vm.deleteFileConfirm=$$v},expression:"deleteFileConfirm"}})],1)],1)],1)]})],2):_c('div',[_vm._v(" Пока нет файлов ")])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }